<template>
    <div>
        <page-header :title="`Chỉnh sửa danh mục`" :items="breadcrumbs" />
        <div v-if="!loading">
            <b-row>
                <b-col lg="12">
                    <b-card>
                        <b-form-group>
                            <label>Tên danh mục <span class="red">*</span></label>
                            <b-input v-model="form.name" />
                        </b-form-group>
                        <label>Logo <span class="red">*</span></label>
                        <b-form-group col-lg="4">
                            <a-thumbnail class="my-pond" :value="form.preview" @input="(value) => (form.preview = value)" />
                        </b-form-group>
                        <b-form-group>
                            <label>Thứ tự</label>
                            <b-input type="number" v-model.number="form.order" min="1" max="50" />
                        </b-form-group>
                        <b-form-group>
                            <div v-if="showAddErrorMessage" class="alert alert-danger" role="alert">
                                {{ addErrorMessage }}
                            </div>
                            <b-button variant="success" class="mr-2" @click.prevent="create">
                                Lưu
                            </b-button>
                            <b-button variant="danger" @click.prevent="toListView">
                                Huỷ
                            </b-button>
                        </b-form-group>
                    </b-card>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import PageHeader from '../../../components/page-header';
import AThumbnail from './partials/a-thumbnail';
import {
    httpClient
} from "../../../_utils/httpClient";
import Swal from 'sweetalert2';

import _ from "lodash";

export default {
    name: 'create',
    components: {
        PageHeader,
        AThumbnail,
    },
    data: function () {
        return {
            breadcrumbs: [{
                text: 'Trang chủ',
                to: '/admin/home',
            }, {
                text: 'Thiết lập danh mục',
                to: '/admin/config-category',
            }, {
                text: 'Chỉnh sửa',
                to: '#',
                active: true,
            },],
            showAddErrorMessage: false,
            addErrorMessage: '',
            form: {
                name: '',
                preview: '',
                order: 1,
            },
            loading: false,
            underUsers: [],
        };
    },
    watch: {
        'form.preview': {
            handler() {
            }
        }
    },
    methods: {
        async toListView() {
            await this.$router.push({
                name: 'admin.config.category'
            })
        },
        validator() {
            if (this.form.name < 1 || this.form.name == null) {
                this.showAddErrorMessage = true;
                this.addErrorMessage = `Tên danh mục không được bỏ trống`;
                return false;
            }
            if (this.form.preview == "" || this.form.preview.length < 1) {
                this.showAddErrorMessage = true;
                this.addErrorMessage = `Logo không được bỏ trống`;
                return false;
            }
            return true
        },
        async getUnderUser() {
            let response = await httpClient.get(`user/under`)
            let responseData = response.data;
            if (responseData.code === 0) {
                this.underUsers = responseData.data
            }
        },
        async create() {
            this.showAddErrorMessage = false
            if (!this.validator()) return
            if(!this.form.order) this.form.order = 0;
            let payload = {
                ...this.form,
            }
            await Swal.queue([{
                title: 'Chỉnh sửa danh mục này?',
                confirmButtonText: 'xác nhận',
                confirmButtonColor: '#34c38f',
                showCancelButton: true,
                cancelButtonText: 'Hủy bỏ',
                cancelButtonColor: '#f46a6a',
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                    let response = await httpClient.post(`config`, payload)
                    if (response.data.code === 0) {
                        Swal.insertQueueStep({
                            title: 'Chỉnh sửa danh mục thành công',
                        });
                        await this.toListView()
                    } else {
                        Swal.insertQueueStep({
                            title: 'Chỉnh sửa danh mục thành công',
                        });
                        this.showAddErrorMessage = true
                        this.addErrorMessage = response.data
                    }
                    return true;
                },
            },]);
        },
        async getById(id) {
            let response = await httpClient.get(`config`, {
                params: {
                    id,
                }
            })
            return response.data.data
        },
    },
    async created() {
        let cloneItem = _.cloneDeep(this.$route.params.form);
        if (cloneItem === undefined) {
            cloneItem = await this.getById(this.$route.params.id);
        }
        this.form = {
            ...cloneItem
        }
        await this.getUnderUser();
        document.querySelector('.my-pond').addEventListener('FilePond:removefile', () => {
            this.form.preview = ""
            console.log(this.form.preview);
        });
    },
};
</script>

<style lang="scss" scoped>
.action-column {
    width: 120px;
    text-align: center;
}

.red {
    color: red;
}

.hide-img {
    height: 0px;
    overflow: hidden;
    pointer-events: none;
    visibility: hidden;
    opacity: 0;
}
</style>
